import React, { useEffect, useState } from 'react';
import {
    Image, Box, Text, Flex, SimpleGrid, useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button
} from '@chakra-ui/react';
import { StyleSheet, css } from 'aphrodite';
import { useSelector, useDispatch } from "react-redux";
import { fetchData } from 'actions/actions';

function NewsCards() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedImage, setSelectedImage] = useState(null);  // State to track the selected image
    const dispatch = useDispatch();
    const isMobile = useSelector(state => state.windowSize.isMobile);
    
    useEffect(() => {
        // Dispatch the fetchData action when the component mounts
        dispatch(fetchData());
    }, [dispatch]);

    const openModalWithImage = (imageSrc) => {
        setSelectedImage(imageSrc);  // Set the image based on the clicked card
        onOpen();
    };

    return (
        <>
            <SimpleGrid
                columns={isMobile ? 1 : 3}
                spacing={10}
                className={css(styles.gridStyle)}
                mt={isMobile ? 3 : 5}
            >
                <Box className={css(styles.card)} onClick={() => openModalWithImage('EDI en.jpg')}>
                    <Text className={css(styles.heading)}>
                        Equity Diversity and Inclusion (EDI) Workshops
                    </Text>
                    <Text className={css(styles.subtitle)}>
                        December 16th 2024
                    </Text>
                    <Flex justifyContent='center'>
                        <hr style={{ width: '50%', borderWidth: '2px' }} />
                    </Flex>
                    <Text className={css(styles.subtitle)}>
                        535 LEGGET DRIVE, SUITE 200, KANATA, ON K2K 3B8
                    </Text>
                </Box>

                <Box className={css(styles.card)}>
                    <Text className={css(styles.heading)}>
                        TRAVERSAL Workshop in conjunction with IEEE World Forum on the Internet of Things 
                    </Text>
                    <Text className={css(styles.subtitle)}>
                        November 10th 2024
                    </Text>
                    <Flex justifyContent='center'>
                        <hr style={{ width: '50%', borderWidth: '2px' }} />
                    </Flex>
                    <Text className={css(styles.subtitle)}>
                        Westin - Ottawa
                    </Text>
                </Box>

                <Box className={css(styles.card)} onClick={() => openModalWithImage('NewsHack.jpg')}>
                    <Text className={css(styles.heading)}>
                        TAVERSAL ANNUAL WORKSHOP and HACKATHON 
                    </Text>
                    <Text className={css(styles.subtitle)}>
                        June 20st 2024 - June 22nd 2024
                    </Text>
                    <Flex justifyContent='center'>
                        <hr style={{ width: '50%', borderWidth: '2px' }} />
                    </Flex>
                    <Text className={css(styles.subtitle)}>
                        535 LEGGET DRIVE, SUITE 200, KANATA, ON K2K 3B8
                    </Text>
                </Box>

                <Box className={css(styles.card)}>
                    <Text className={css(styles.heading)}>
                        NEM-Ontario Event co-located with TRAVERSAL Technology Transfer and Entrepreneurship (TTE) series 
                    </Text>
                    <Text className={css(styles.subtitle)}>
                        March 25th 2024 - March 30th 2024
                    </Text>
                    <Flex justifyContent='center'>
                        <hr style={{ width: '50%', borderWidth: '2px' }} />
                    </Flex>
                    <Text className={css(styles.subtitle)}>
                        535 LEGGET DRIVE, SUITE 200, KANATA, ON K2K 3B8
                    </Text>
                </Box>
                
                <Box className={css(styles.card)} onClick={() => openModalWithImage('NewsSurvey.jpg')}>
                    <Text className={css(styles.heading)}>
                        TRAVERSAL SURVEY AND TUTORIAL Workshop 
                    </Text>
                    <Text className={css(styles.subtitle)}>
                        February 20th 2024 - March 1st 2024
                    </Text>
                    <Flex justifyContent='center'>
                        <hr style={{ width: '50%', borderWidth: '2px' }} />
                    </Flex>
                    <Text className={css(styles.subtitle)}>
                        535 LEGGET DRIVE, SUITE 200, KANATA, ON K2K 3B8
                    </Text>
                </Box>

                {/* First card */}
                <Box className={css(styles.card)} onClick={() => openModalWithImage('NewsEDI.jpg')}>
                    <Text className={css(styles.heading)}>
                        CREATE TRAVERSAL EDI Workshop  
                    </Text>
                    <Text className={css(styles.subtitle)}>
                        December 18th 2023 - December 22nd 2023 
                    </Text>
                    <Flex justifyContent='center'>
                        <hr style={{ width: '50%', borderWidth: '2px' }} />
                    </Flex>
                    <Text className={css(styles.subtitle)}>
                        535 LEGGET DRIVE, SUITE 200, KANATA, ON K2K 3B8
                    </Text>
                </Box>


            </SimpleGrid>

            {/* Modal */}
            <Modal isOpen={isOpen} onClose={onClose} isCentered size='3xl'>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>About Event</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {selectedImage && <Image src={selectedImage} alt='Selected image' width='100%' />}
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='red' mr={3} onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

const styles = StyleSheet.create({
    tabParent: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#DC242C'
    },
    heading: {
        fontWeight: 'bold',
        fontSize: '18',
        textAlign: 'center',
        marginTop: 5
    },
    subtitle: {
        fontSize: '20',
        textAlign: 'center',
        marginTop: 5,
        fontWeight: 'bold'
    },
    card: {
        borderWidth: '1px',
        borderRadius: '5px',
        borderColor: 'gray',
        justifyContent: 'center',
        textAlign: 'center',
        ':hover': {
          cursor: 'pointer'  
        },
        position: 'relative'
    },
    gridStyle: {
        justifyContent: 'center',
        marginBottom: 10,
        marginLeft: 10,
        marginRight: 10
    }
})

export default NewsCards